import { ReactNode } from 'react';

import { TranslationsProvider } from '../../Shared/locale';
import NotificationProvider from '../../Shared/Notifier/NotificationProvider';

export default function Layout({
  children,
}: {
  children?: ReactNode;
}) {
  return (
    <TranslationsProvider>
      <NotificationProvider>
        {children}
      </NotificationProvider>
    </TranslationsProvider>
  );
}
