import Vapor from 'laravel-vapor';

import { Charity } from '../../Shared/types';
import { useRouter } from '../router';

export default function Logo({
  charity,
}:{
  charity: Charity | null;
}) {
  const { routes } = useRouter();

  return (
    <div className="flex justify-center my-12">
      {charity?.logo_url && (
        <a href={routes.charity_page(charity.id)}>
          <img src={charity.logo_url} className="block w-32 mx-auto" />
        </a>
      )}
      {!charity?.logo_url && (
        <a href="https://supporta.cc">
          <img src={Vapor.asset('images/logo.svg')} width="100" alt="Supporta" />
        </a>
      )}
    </div>
  );
}
